import Storage from '../../utils/Storage';

export default {
    state() {
        return {
            chatMessageUserIdTo: Storage.getItem('chatMessageUserIdTo') || null,
            chatMessageUserTo: Storage.getItem('chatMessageUserTo') || null,
            chatMessageDrafts: Storage.getItem('chatMessageDrafts') || {}, // для хранения черновиков сообщений
            unread: [],
            isTyping: [],
            markAsRead: null,
            isMobile: false,
        }
    },
    mutations: {
        setChatMessageUserIdTo(state, payload) {
            state.chatMessageUserIdTo = payload
            Storage.setItem('chatMessageUserIdTo', payload);

        },
        setChatMessageUserTo(state, payload) {
            state.chatMessageUserTo = payload
            Storage.setItem('chatMessageUserTo', payload);
        },
        setChatMessageDraft(state, { userIdTo, text }) {
            // Сохраняем черновик для конкретного пользователя
            state.chatMessageDrafts = {
                ...state.chatMessageDrafts,
                [userIdTo]: text,
            };

            Storage.setItem('chatMessageDrafts', state.chatMessageDrafts);
        },
        setChatMessageUnread(state, payload) {
            state.unread = payload
        },
        setChatMessageIsTyping(state, payload) {
            state.isTyping = payload
        },
        setChatMessageMarkAsRead(state, payload) {
            state.markAsRead = payload
        },
        setChatMessageIsMobile(state, payload) {
            state.isMobile = payload
        },
    },
    getters: {
        getChatMessageUserIdTo(state) {
            return state.chatMessageUserIdTo
        },
        getChatMessageUserTo(state) {
            return state.chatMessageUserTo
        },
        getChatMessageDraft(state) {
            return (userIdTo) => state.chatMessageDrafts[userIdTo] || ''; // Получаем черновик для конкретного пользователя
        },
        getChatMessageUnread(state) {
            return state.unread
        },
        getChatMessageIsTyping(state) {
            return state.isTyping
        },
        getChatMessageMarkAsRead(state) {
            return state.markAsRead
        },
        getChatMessageIsMobile(state) {
            return state.isMobile
        },
    }
}