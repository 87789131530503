<template>
  <router-link :to="{ name: 'TaskUpdate', params: { id: data.data.id } }">
  <Message
    class="m-2 mr-3 cursor-pointer" :icon="true"
  >
    <div>
      <div>
        {{ data.data.message }}
      </div>
      <div class="d-flex align-items-center">
        <div>
          <i :class="'me-2 ' + data.data.icon"></i>
        </div>
          <div style="width: 240px">
            <span :title="data.data.description || data.data.typeName">{{
              data.data.description || data.data.typeName
            }}</span>
          </div>
      </div>
      <div class="d-flex align-items-center">
        <div>
          <i class="me-2 fa-regular fa-clock"></i>
        </div>
        <div class="text-muted fs-7">
          {{ $str.datetime(data.createdAt) }}
        </div>
      </div>
    </div>
  </Message>
</router-link>
</template>

<script>
import Message from 'primevue/message';

export default {
  components: {
    Message,
  },
  props: {
    data: Object,
  },
};
</script>