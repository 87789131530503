<template>
  <li @click="onClickRead()">
    <template v-if="$store.getters.getTaskNotification.length">
      <i
        class="bi bi-bell cursor-pointer"
        style="font-size: 1.2rem; color: #6f7985"
        v-tooltip.bottom="$t('Notifications')"
        v-badge.danger="$store.getters.getTaskNotification.length"
      ></i>
    </template>
    <template v-else>
      <i
        class="bi bi-bell cursor-pointer"
        style="font-size: 1.2rem; color: #6f7985"
        v-tooltip.bottom="$t('Notifications')"
      ></i>
    </template>
  </li>
  <Sidebar
    v-model:visible="visibleNotification"
    @hide="getNotification()"
    position="right"
    class="w-full md:w-20rem lg:w-30rem"
  >
    <h5 class="pl-2">{{ $t('Notifications') }}</h5>
    <div class="m-2">
      <Button
        :label="$t('Subscribe to push')"
        icon="bi bi-bell"
        class="p-button p-button-sm p-button-outlined"
        @click="subscribe"
      />
    </div>
    <template v-if="$store.getters.getTaskNotification.length">
      <template v-for="(unreadNotification, i) of $store.getters.getTaskNotification" :key="i">
        <template v-if="unreadNotification.typeName == 'TaskNotification'">
          <TaskNotification :data="unreadNotification" />
        </template>
      </template>
    </template>
    <template v-else> <span class="pl-2">Новых уведомлений нет</span> </template>
  </Sidebar>
</template>

<script>
import Badge from 'primevue/badge';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import NotificationService from '../services/NotificationService';
import TaskNotification from '../../task/components/TaskNotification.vue';

export default {
  components: {
    Badge,
    Sidebar,
    TaskNotification,
    Button,
  },
  data() {
    return {
      service: new NotificationService({
        expand: ['typeName'],
      }),
      visibleNotification: false,
      unreadNotificationList: [],
    };
  },
  async created() {
    this.getNotification();
  },
  methods: {
    async getNotification() {
      this.$store.commit('setTaskNotification', await this.service.getUnreadNotification());
    },
    async onClickRead() {
      this.visibleNotification = true;
      await this.service.markAsRead();
    },
    async subscribe() {
      let permission = await Notification.requestPermission();
      if (permission === 'granted') {
        // Теперь инициализируем получение FCM токена
        if (await this.service.firebase()) {
          this.$toast.success({ detail: 'Уведомления подключены' });
        } else {
          this.$toast.error({ detail: this.service.getError() });
        }
      } else {
        // Handle denied permission
      }
    },
  },
};
</script>