import Service from '@/services/Service';
import TaskCommentService from './TaskCommentService';
import TaskListService from './TaskListService';
import http from '@/utils/http-common';

class TaskService extends Service {

    basePath = 'task';

    newObject() {
        return new TaskService();
    }

    async typeList() {
        return await http.get('/' + this.basePath + '/type-list', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async statusList() {
        return await http.get('/' + this.basePath + '/status-list', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async periodList() {
        return await http.get('/' + this.basePath + '/period-list', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Get count
     * @returns int
     */
    async count() {
        return await http.get('/' + this.basePath + '/count', { params: this.prepareRequest() }).then(response => {
            if (response.data.data) {
                return response.data.data.count;
            }
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Count for button a tasks (all, working, completed)
     * @returns int
     */
    async countStatus() {
        return await http.get('/' + this.basePath + '/count-status', { params: this.prepareRequest() }).then(response => {
            if (response.data.data) {
                return response.data.data;
            }
        }).catch(e => this.catchResponse(e));
    }

    async overdue() {
        return await http.get('/' + this.basePath + '/overdue', { params: this.prepareRequest() }).then(response => {
            return response.data;
        }).catch(e => this.catchResponse(e));
    }

    /** @inheritdoc */
    setAttributes(attributes) {
        super.setAttributes(attributes);

        if (this.comments) {
            let taskCommentService = new TaskCommentService();
            this.comments = taskCommentService.collection(this.comments);
        }

        if (this.lists) {
            let taskListService = new TaskListService();
            this.lists = taskListService.collection(this.lists);
        }
    }

}

export default TaskService;