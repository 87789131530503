import Service from '@/services/Service';
import http from '@/utils/http-common';

class PbxUserService extends Service {

    basePath = 'pbx/user';

    newObject() {
        return new PbxUserService();
    }

    /**
     * Get token
     * @returns response
     */
    async token() {
        return await http.get('/' + this.basePath + '/token', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }
}

export default PbxUserService;