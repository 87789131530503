<template>
  <div
    v-if="this.$store.getters.loadingPage"
    class="bg-light position-absolute top-0 end-0 bottom-0 start-0 opacity-75"
    style="z-index: 1000"
  >
    <ProgressSpinner class="top-50 start-50 translate-middle" />
    <div class="position-absolute top-50 start-50 translate-middle fs-7">{{ $t('Loading') }}</div>
  </div>
</template>
<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
  components: { ProgressSpinner },
};
</script>