<template>
  <LoadingPage />
  <PbxZadarmaCallDialog />
  <!-- <div class="min-h-screen flex relative"> -->
  <div
    v-if="!loading"
    :class="
      'wrapper ' +
      [
        $route.name == 'ContractKanban' ||
        $route.name == 'TaskUpdate' ||
        $route.name == 'TaskCreate' ||
        $route.name == 'ContractCreate' ||
        $route.name == 'ContractUpdate'
          ? 'wrapper-vh'
          : '',
      ] +
      [$route.name == 'TaskUpdate' || $route.name == 'TaskCreate' ? ' tasks' : '']
    "
  >
    <LeftSideBar v-model:sidebarVisible="sidebarVisible" v-model:sidebarModal="sidebarModal" />
    <div class="content-page">
      <div class="content" :style="'margin-left: ' + (sidebarVisible && !sidebarModal ? '272px' : 0)">
        <TopSideBar v-model:sidebarVisible="sidebarVisible" />
        <router-view :key="$store.getters.routerViewKey" />
        <!-- <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view> -->
      </div>
    </div>
  </div>
  <!-- </div> -->

  <ToastInit />

  <ConfirmDialog></ConfirmDialog>
  <!-- <SidebarProfile /> -->

  <ModelSideBar />
</template>

<script>
import { loadScript } from 'vue-plugin-load-script';
import Favico from 'favico.js';
import Echo from 'laravel-echo';
import http from '../utils/http-common';
import ConfirmDialog from 'primevue/confirmdialog';
import Env from '../utils/Env';
import ToastInit from '@/components/ToastInit.vue';
import LoadingPage from '../components/ui/LoadingPage.vue';
import LeftSideBar from '../components/LeftSideBar.vue';
import TopSideBar from '../components/TopSideBar.vue';
import ModelSideBar from '../components/ModelSideBar.vue';
import NotificationService from '../modules/notification/services/NotificationService';
import PbxUserService from '../modules/pbx/services/PbxUserService';
import SearchByService from '../modules/search/services/SearchByService';
import PbxZadarmaCallDialog from '../modules/pbx/components/PbxZadarmaCallDialog.vue';
// import SidebarProfile from '../components/user/SidebarProfile.vue';

export default {
  components: {
    ToastInit,
    ConfirmDialog,
    LoadingPage,
    LeftSideBar,
    TopSideBar /*, SidebarProfile*/,
    ModelSideBar,
    PbxZadarmaCallDialog,
  },
  data() {
    return {
      sidebarVisible: false,
      sidebarModal: true,
      loading: true, // это нужно т.к. created не совсем async, он все равно синхронно пропускает рендеринг
      notificationService: new NotificationService({
        expand: ['typeName'],
      }),
      pbxUserService: new PbxUserService(),
      searchByService: new SearchByService(),
      zdrmApi: null,
      zdrmApiPhoneStatus: null,
      favico: null,
      pushNotificationsEnabled: false,
    };
  },
  async created() {
    // для всплывающего уведомления через моб версию
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker
    //     .register('/service-worker.js')
    //     .then((registration) => {
    //       console.log('Service Worker зарегистрирован:', registration);
    //       this.pushNotificationsEnabled = true;
    //     })
    //     .catch((error) => {
    //       console.error('Ошибка при регистрации Service Worker:', error);
    //     });
    // }

    // @todo: для фавикона бадж, не работает, доделать
    // this.favico = new Favico({
    //   animation: 'none',
    //   bgColor: '#d9534f',
    // });

    // get user and struc info
    await this.$store.dispatch('auth/init');

    this.loading = false;

    this.sockets();

    if (this.$store.getters['auth/isAppIdentifier']('zadarma')) {
      if (await this.zadarma()) {
        window.addEventListener('load', (event) => {
          this.zdrmApi = zdrmWebrtcPhone;
          this.zadarmaWatch();
        });
      }
    }
    window.onload = () => {};
  },
  watch: {
    '$store.getters.getChatMessageUnread.totalCount'() {
      if (this.$store.getters.getChatMessageUnread.totalCount) {
        document.title = '(' + this.$store.getters.getChatMessageUnread.totalCount + ') ' + 'vionvi';
        // this.favico.badge(10);

        // if (Notification.permission === 'granted') {
        //   // Для моб уведомления (тест)
        //   const options = {
        //     body: 'Текст уведомления',
        //     icon: 'icon.png',
        //   };

        //   const notification = new Notification('Заголовок уведомления', options);

        //   notification.onclick = function (event) {
        //     console.log('Action');
        //   };
        // }

        // всплывающее уведомление через браузер
        // if (this.pushNotificationsEnabled && 'serviceWorker' in navigator) {
        //   // Отправить push-уведомление на сервисный работник
        //   navigator.serviceWorker.ready.then((registration) => {
        //     const options = {
        //       body: 'Тестовое уведомление 1',
        //     };
        //     registration.showNotification('Тестовое уведомление 2', options);
        //   });
        // }
      } else {
        // this.favico.badge(0);
        // @todo: Сделать с учетом динамических title из breadcrubmbs
        // document.title = 'vionvi';
      }
    },
  },
  methods: {
    // async zadarmaWatch() {
    //   setInterval(async () => {
    //     if (this.zdrmApiPhoneStatus !== this.zdrmApi.status) {
    //       this.zdrmApiPhoneStatus = this.zdrmApi.status;

    //       if (this.zdrmApiPhoneStatus === 'call') {
    //         var phone = this.zdrmApi.number || this.zdrmApi.apiWidget.callTo;
    //         var search = await this.searchByService.phone(phone);

    //         this.$store.commit('setPbxZadarmaToCall', {
    //           type: 'call',
    //           phone: phone,
    //           name: search.name || 'Неизвестный',
    //         });
    //       } else if (this.zdrmApiPhoneStatus === 'confirmed') {
    //         this.$store.commit('setPbxZadarmaToCall', {
    //           type: 'confirmed',
    //         });
    //       } else if (this.zdrmApiPhoneStatus === 'incoming' || this.zdrmApiPhoneStatus === 'confirmed') {
    //         var search = await this.searchByService.phone(this.zdrmApi.number);

    //         var type = this.zdrmApiPhoneStatus === 'confirmed' ? 'confirmed' : 'incoming';

    //         this.$store.commit('setPbxZadarmaToCall', {
    //           type: type,
    //           phone: this.zdrmApi.number,
    //           name: search.name || 'Неизвестный',
    //         });
    //       } else if (
    //         this.zdrmApiPhoneStatus === 'canceled' ||
    //         this.zdrmApiPhoneStatus === 'rejected' ||
    //         this.zdrmApiPhoneStatus === 'busy'
    //       ) {
    //         this.$store.commit('setPbxZadarmaToCall', {
    //           type: this.zdrmApiPhoneStatus,
    //           phone: null,
    //           name: null,
    //         });
    //       } else if (this.zdrmApiPhoneStatus === 'wait') {
    //         // console.log('wait');
    //         this.$store.commit('setPbxZadarmaToCall', {
    //           type: 'wait',
    //           phone: null,
    //           name: null,
    //         });
    //       }
    //     }
    //   }, 1000);
    // },
    async sockets() {
      http
        .get(process.env.VUE_APP_API_URL + '/notification/check-available')
        .then(async (response) => {
          this.websocketsIsRunning = response.data.data.websocketsIsRunning;

          if (this.websocketsIsRunning) {
            this.socketServer = new Echo({
              broadcaster: 'pusher',
              key: process.env.VUE_APP_PUSHER_KEY,
              cluster: 'mt1',
              encrypted: false,
              forceTLS: false,
              wsHost: process.env.VUE_APP_PUSHER_HOST,
              enabledTransports: ['ws', 'wss'],
              authEndpoint: process.env.VUE_APP_WEBSOCKETS_AUTHPOINT,
              wsPort: process.env.VUE_APP_PUSHER_PORT,
              wssPort: process.env.VUE_APP_PUSHER_PORT,
              auth: {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
                },
              },
            });

            await this.socketServer
              .channel(Env.getItem('VUE_APP_ID') + '.public.task.' + this.$store.getters['auth/user'].id)
              // .bind('pusher_internal:subscription_succeeded')
              .listen('.task.event', async (event) => {
                this.$store.commit('setTaskNotification', await this.notificationService.getUnreadNotification());
                this.$toast.add({
                  severity: 'info',
                  summary: event.message,
                  group: 'bl',
                  life: 10000,
                });
              });
          } else {
            console.log('Websockets is not running');
          }
        })
        .catch((e) => console.log(e, 'e'));
    },
    // async zadarma() {
    //   loadScript('https://my.novofon.com/webphoneWebRTCWidget/v8/js/novofon/loader-phone-lib.js?sub_v=68');

    //   loadScript('https://my.novofon.com/webphoneWebRTCWidget/v8/js/loader-phone-fn.js?sub_v=68');

    //   if (!this.$store.getters.getPbxZadarmaToken) {
    //     var pbxUser = await this.pbxUserService.token();

    //     // возникала ошибка, поэтому добавлено, возможно стоит сделать более умную проверку
    //     if (!pbxUser || !pbxUser.token) {
    //       return false;
    //     }

    //     this.$store.commit('setPbxZadarmaToken', {
    //       token: pbxUser.token,
    //       dtExpire: new Date(new Date().getTime() + 172800 * 1000).toISOString(),
    //       pbxId: pbxUser.pbxId,
    //     });
    //   }

    //   if (this.$store.getters.getPbxZadarmaToken) {
    //     if (new Date().toISOString() >= this.$store.getters.getPbxZadarmaToken.dtExpire) {
    //       var pbxUser = await this.pbxUserService.token();
    //       // this.$store.commit('setPbxZadarmaToken', await this.pbxUserService.token());
    //       this.$store.commit('setPbxZadarmaToken', {
    //         token: pbxUser.token,
    //         dtExpire: new Date(new Date().getTime() + 172800 * 1000).toISOString(),
    //         pbxId: pbxUser.pbxId,
    //       });
    //     }

    //     var token = this.$store.getters.getPbxZadarmaToken.token;
    //     var pbxId = this.$store.getters.getPbxZadarmaToken.pbxId;

    //     if (window.addEventListener) {
    //       window.addEventListener(
    //         'load',
    //         function () {
    //           zadarmaWidgetFn(
    //             token,
    //             pbxId,
    //             'rounded' /*square|rounded*/,
    //             'ru' /*ru, en, es, fr, de, pl, ua*/,
    //             true,
    //             '{right:10px;bottom:5px;}'
    //           );
    //         },
    //         false
    //       );
    //     } else if (window.attachEvent) {
    //       window.attachEvent('onload', function () {
    //         zadarmaWidgetFn(
    //           token,
    //           pbxId,
    //           'rounded' /*square|rounded*/,
    //           'ru' /*ru, en, es, fr, de, pl, ua*/,
    //           true,
    //           '{right:10px;bottom:5px;}'
    //         );
    //       });
    //     }

    //     return true;
    //   }
    // },
  },
};
</script>
