import Service from '@/services/Service';

class LangService extends Service {
  basePath = 'lang';

  newObject() {
    return new LangService();
  }
}

export default LangService;
