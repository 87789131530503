import { createI18n } from 'vue-i18n';
import { messages } from '../lang/locales';
import { dateTimeFormats } from '../lang/locales';
import Env from '@/utils/Env';
import Storage from '@/utils/Storage';

const i18n = createI18n({
    locale: Storage.getItem('locale') || Env.getItem('VUE_APP_DEFAULT_LOCAL') || 'en', // set locale
    fallbackLocale: 'us', // set fallback locale
    messages, // set locale messages
    dateTimeFormats
});

export function t(str) {
    return i18n.global.t(str);
}

export default i18n;