<template>
  <div>
    <!-- <FormField
      :service="service"
      field="locale"
      type="select"
      :options="langList"
      optionValue="locale"
      :showClear="false"
      :onChange="onChangeLocale"
    /> -->
  </div>
</template>

<script>
import FormField from '@/components/ui/form/FormField.vue';
import LangService from '@/modules/lang/services/LangService';
import Storage from '@/utils/Storage';

export default {
  components: {
    FormField,
  },
  data() {
    return {
      service: new LangService(),
      langList: [],
    };
  },
  async created() {
    return;
    
    this.langList = await this.service.allList();

    // 1: locale that was changed at the front
    this.service.locale = Storage.getItem('langLocaleChange');

    let checkLocale, defaultLocale;
    for (const lang of this.langList) {
      // check locale for presence in list
      if (this.service.locale && this.service.locale === lang.locale) {
        checkLocale = true;
      }

      if (lang.isDefault) {
        defaultLocale = lang.locale;
      }
    }

    if (!checkLocale) {
      if (defaultLocale) {
        // 2: locale that is set by default on the backend
        this.service.locale = lang.locale;
        this.$store.dispatch('lang/setLocale', lang.locale);
      } else {
        // 3: locale that is set in the store
        this.service.locale = this.$store.getters['lang/locale'];
      }
    }
  },
  methods: {
    async onChangeLocale(event) {
      Storage.setItem('langLocaleChange', event.value);
      this.$store.dispatch('lang/selLocaleWithUser', event.value);
    },
  },
};
</script>