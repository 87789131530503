class Obj {

    /**
     * Get real field
     * @param {Object} obj
     * @param {String|Array} field
     * @returns {mixed}
     */
    getField(obj, field) {
        let serviceF;

        // Берем значение, если оно составное, т.е. передано как массив из вложенных ключей к переменной элемента объекта
        if (typeof field === 'object') {
            serviceF = obj;

            for (let f of field) {
                if (f) {
                    if (serviceF[f] === undefined) {
                        return null;
                    }
                    serviceF = serviceF[f];
                }
            }
        } else {
            serviceF = obj[field];
        }

        return serviceF;
    }

    /**
     * Set field value
     * @param {Object} obj
     * @param {String|Array} field
     * @param {mixed} v
     */
    setField(obj, field, v) {
        if (typeof field === 'object') {
            // Перебором присвоение не сработало, поэтому сравниваем по кол-ву элементов
            if (obj[field[0]] === undefined) {
                obj[field[0]] = {};
            }
            if (field.length === 2) {
                if (field[1]) {
                    obj[field[0]][field[1]] = v;
                } else {
                    // для значений в массиве
                    obj[field[0]] = v === undefined ? v : [v];
                }
            } else if (field.length === 3) {
                if (obj[field[0]][field[1]] === undefined) {
                    obj[field[0]][field[1]] = {};
                }
                obj[field[0]][field[1]][field[2]] = v;
            }
        } else {
            obj[field] = v;
        }
    }

}

export default new Obj();