<template>
  <a v-if="to && to.href" :href="typeof to.href === 'function' ? to.href(data) : to.href" :target="to.target">
    <slot></slot>
  </a>
  <router-link v-else-if="routerTo" :to="routerTo" :target="routerTo.target" v-slot="{ isActive }">
    <slot :class="{ 'router-link-active': isActive }"></slot>
  </router-link>
  <span v-else>
    <slot></slot>
  </span>
</template>
<script>
export default {
  props: {
    to: String | Object | Function,
    data: Object,
  },
  computed: {
    routerTo() {
      let to = typeof this.to === 'function' ? this.to(this.data) : this.to;

      if (!this.$store.getters.canTo(to)) {
        return null;
      }

      return to;
    },
  },
};
</script>