import Service from '@/services/Service';
import http from '@/utils/http-common';

class SearchService extends Service {

    basePath = 'search';

    newObject() {
        return new SearchService();
    }

    /**
     * Request for pointing search
     * 
     * @returns data
     */
    async point() {
        return await http.get('/' + this.basePath + '/point', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }
}

export default SearchService;