import Service from '@/services/Service';

class TaskCommentService extends Service {

    basePath = 'task/comment';

    newObject() {
        return new TaskCommentService();
    }
}

export default TaskCommentService;