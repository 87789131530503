import Service from '@/services/Service';

class LeadCommentService extends Service {

    basePath = 'lead/comment';

    newObject() {
        return new LeadCommentService();
    }
}

export default LeadCommentService;