import Storage from '../../utils/Storage';

export default {
  state() {
    return {
      clientData: {},
      orgData: {},
      contractData: {},
      leadData: {},
    };
  },
  mutations: {
    setClientData(state, payload) {
      state.clientData = payload;
    },
    setOrgData(state, payload) {
      state.orgData = payload;
    },
    setContractData(state, payload) {
      state.contractData = payload;
    },
    setLeadData(state, payload) {
      state.leadData = payload;
    },
  },
  getters: {
    getClientData(state) {
      return state.clientData
    },
    getOrgData(state) {
      return state.orgData
    },
    getContractData(state) {
      return state.contractData
    },
    getLeadData(state) {
      return state.leadData
    }
  }
};
