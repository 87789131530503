import Storage from '../../utils/Storage';

export default {
    state() {
        return {
            token: Storage.getItem('pbx-zadarma-token'),
            toCall: {
                type: null,
                phone: null,
                name: null,
            },
            // expireIn: Storage.getItem('pbx-zadarma-expire-in'),
        }
    },
    mutations: {
        setPbxZadarmaToken(state, payload) {
            state.token = payload
            Storage.setItem('pbx-zadarma-token', payload);
        },
        setPbxZadarmaToCall(state, payload) {
            state.toCall = {
                type: payload.type,
                phone: payload.phone,
                name: payload.name,
            }
        },
    },
    getters: {
        getPbxZadarmaToken(state) {
            return state.token
        },
        getPbxZadarmaToCall(state) {
            return state.toCall
        },
    }
}