import Service from '@/services/Service';
import http from '@/utils/http-common';

class ChatMessageService extends Service {

  basePath = 'chat/message';

  newObject() {
    return new ChatMessageService();
  }

  /**
   * Mark as read message
   */
  async markAsRead(userId) {
    return await http.put('/' + this.basePath + '/mark-as-read/' + userId, { params: this.prepareRequest() }).then(response => {
      return response.data;
    }).catch((e) => this.catchResponse(e));
  }

  /**
   * Count unread message
   */
  async unreadMessageCount() {
    return await http.get('/' + this.basePath + '/unread-message-count', { params: this.prepareRequest() }).then(response => {
      return response.data.data;
    }).catch((e) => this.catchResponse(e));
  }

  /**
   * Get last message
   */
  async getLastMessage() {
    return await http.get('/' + this.basePath + '/get-last-message', { params: this.prepareRequest() }).then(response => {
      return response.data.data;
    }).catch((e) => this.catchResponse(e));
  }

  /**
   * Truncate text
   * @param {string} text 
   * @param {int} length 
   * @returns string
   */
  truncateText(text, length) {
    console.log('text', text);
    if (text.length > length) {
      return text.slice(0, length) + '...';
    }
    return text;
  }

  /**
   * Get icons
   * @returns array
   */
  getIsons() {
    return [
      '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇', '🙂', '😎', '🤩', '😍', '🥰', '😘', '😗', '😋',
      '😛', '😜', '🤪', '😝', '🤑', '🤗', '🤭', '🤫', '🤔', '🤐', '🤨', '😐', '😑', '😶', '😏', '😒', '🙄', '😬',
      '🤥', '😌', '😔', '😪', '🤤', '😴', '😷', '🤒', '🤕', '🤢', '🤮', '🤧', '🥵', '🥶', '🥴', '😵', '🤯', '🤠',
      '🥳', '😎', '🤓', '🧐', '😕', '😟', '🙁', '😮', '😯', '😲', '😳', '🥺', '😦', '😧', '😨', '😰', '😥', '😢',
      '😭', '😱', '😖', '😣', '😞', '😓', '😩', '😫', '🥱', '😤', '😡', '😠', '🤬', '😈', '👿', '💀', '💩',
    ];
  }
}

export default ChatMessageService;
