<template>
  <Dialog
    v-model:visible="isShowDialog"
    modal
    :closable="false"
    :closeOnEscape="false"
    :header="getNameDialog"
    :style="{ width: '30vw' }"
  >
    <div class="d-flex flex-column align-items-center">
      <div v-if="statusName" class="mb-3">{{ statusName }}</div>
      <Skeleton v-if="!$store.getters.getPbxZadarmaToCall.name" height="2rem" class="mb-3"></Skeleton>
      <div v-else class="mb-3">{{ $store.getters.getPbxZadarmaToCall.name }}</div>
      <Skeleton v-if="!$store.getters.getPbxZadarmaToCall.phone" height="2rem" class="mb-3"></Skeleton>
      <div v-else class="mb-3">{{ $str.phone($store.getters.getPbxZadarmaToCall.phone) }}</div>

      <!-- <button @click="onSwitchMicrophone">Microfone</button> -->

      <!-- <div class="my-2">
        Вызов...
      </div> -->

      <div class="d-flex">
        <Button
          v-if="$store.getters.getPbxZadarmaToCall.type === 'incoming'"
          @click="onAcceptCall"
          icon="bi bi-telephone"
          class="me-5 p-button-success p-button-rounded"
          aria-label="Cancel"
        />
        <Button
          @click="onDownCall"
          icon="bi bi-telephone-x"
          class="p-button-danger p-button-rounded"
          aria-label="Cancel"
        />
      </div>
    </div>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Skeleton from 'primevue/skeleton';
import PbxZadarmaService from '../../pbx/services/PbxZadarmaService';

export default {
  components: {
    Button,
    Dialog,
    Skeleton,
  },
  props: {
    phone: Number,
  },
  data() {
    return {
      pbxZadarmaService: new PbxZadarmaService(),
      isShowDialog: false,
      // apiWidget: null,
      status: null,
      statusName: null,
      name: this.$store.getters.getPbxZadarmaToCall.name,
    };
  },
  computed: {
    getNameDialog() {
      // if (this.$store.getters.getPbxZadarmaToCall.type === 'call') {
      //   return 'Исходящий звонок';
      // } else if (this.$store.getters.getPbxZadarmaToCall.type === 'incoming') {
      //   return 'Входящий звонок';
      // }
      // Потому по типу будем определять
      return 'Звонок';
    },
  },
  watch: {
    '$store.getters.getPbxZadarmaToCall.type'() {
      let type = this.$store.getters.getPbxZadarmaToCall.type;
      if (type == 'incoming' || type == 'call') {
        this.isShowDialog = true;
      } else if (type == 'canceled' || type == 'rejected' || type == 'busy') {
        this.statusName = type;
        setTimeout(() => {
          this.onDownCall();
        }, 3000);
      } else if (type == 'wait') {
        this.isShowDialog = false;
        // this.onDownCall();
      }
    },
  },
  methods: {
    // makeCall() - функция для осуществления исходящего звонка.
    // answerCall() - функция для ответа на входящий звонок.
    // finishCall() - функция для завершения звонка.
    // muteMicrophone() - функция для выключения микрофона во время звонка.
    // unmuteMicrophone() - функция для включения микрофона во время звонка.
    // muteSpeaker() - функция для выключения динамика во время звонка.
    // unmuteSpeaker() - функция для включения динамика во время звонка.
    onSwitchMicrophone() {
    //   zdrmWPhI.finishCall();
    },
    onClick() {
      // console.log('status', zdrmWebrtcPhone.status);
      this.isShowDialog = true;
      // if (zdrmWebrtcPhone) {
      //   this.apiWidget = zdrmWebrtcPhone.apiWidget;
      // }
      this.pbxZadarmaService.call(this.phone);
    },
    onAcceptCall() {
        // console.log(zdrmWPhI);
        zdrmWPhI.answer();
    //   zdrmWPhI.acceptCall();
        // zdrmWPhI.answerCall();
    },
    onHangupCall() {
      console.log('hangupCall');
      this.isShowDialog = false;
      zdrmWPhI.hangupCall();
    },
    onDownCall() {
      this.isShowDialog = false;
      zdrmWPhI.finishCall();
      // console.log('finish', zdrmWebrtcPhone);
    },
  },
};
</script>