<template>
  <div class="vionvi-ground">
    <div class="container">
      <LoadingPage />

      <router-view />
    </div>
  </div>
</template>

<script>
import LoadingPage from '../components/ui/LoadingPage.vue';

export default {
  components: { LoadingPage },
};
</script>
