import Service from '@/services/Service';

class PbxZadarmaService extends Service {

    /**
     * Call zadarma
     */
    call(phone) {
        if (phone) {
            // console.log('phone', zdrmWebrtcPhone);
            const zdrmWPhI = document.querySelector('#zdrmWPhI');
            zdrmWPhI.style.display = 'none';

            let zInput = document.getElementById('zdrm-webphone-phonenumber-input');
            zInput.value = phone;

            let zBtn = document.getElementsByClassName('zdrm-webphone-call-btn');
            zBtn[0].click();
        }
    }
}

export default PbxZadarmaService;