<template>
  <template v-for="(modal, i) of $store.getters.modals" :key="i">
    <Sidebar
      v-model:visible="$store.getters.modals[i].isOpen"
      position="right"
      :class="
        'vionvi-ground p-sidebar-' +
        ($store.getters.modals[i].size || 'lg') +
        ' p-sidebar-' +
        ($store.getters.modals[i].size || 'lg') +
        '-' +
        (i > 5 ? 5 : i)
      "
      @hide="onUpdateModelValue(modal)"
    >
      <template #container>
        <span>
          <Button
            @click="$store.getters.modals[i].isOpen = false"
            icon="pi pi-times"
            v-tooltip="$t('Close')"
            text
            rounded
            class="btn-close-sidebar"
          ></Button>
        </span>
        <div class="p-sidebar-content">
          <component :is="$store.getters.modals[i].component" />
        </div>
      </template>
    </Sidebar>
  </template>
</template>

<script>
import Button from 'primevue/button';
import Sidebar from 'primevue/sidebar';

export default {
  components: { Sidebar, Button },
  methods: {
    onUpdateModelValue(modal) {
      if (!modal.isAutoClose) {
        window.history.back();
      }
    },
  },
};
</script>