import Service from '@/services/Service';

class TaskListItemService extends Service {

    basePath = 'task/list-item';

    newObject() {
        return new TaskListItemService();
    }
}

export default TaskListItemService;