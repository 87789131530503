import Service from '@/services/Service';
import http from '@/utils/http-common';
import LeadCommentService from '@/modules/lead/services/LeadCommentService';
import TaskService from '@/modules/task/services/TaskService';
import PbxCallService from '@/modules/pbx/services/PbxCallService';

class LeadService extends Service {

    basePath = 'lead';

    newObject() {
        return new LeadService();
    }

    /**
     * Get count
     * @returns int
     */
    async count() {
        return await http.get('/' + this.basePath + '/count', { params: this.prepareRequest() }).then(response => {
            if (response.data.data) {
                return response.data.data.count;
            }
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Get item by id
     * 
     * @param {int} id
     * @returns {Service}
     */
    async showSearch(id) {
        id = id || this.id;

        await http.get('/' + this.basePath + '/show-search/' + id, { params: this.prepareRequest() }).then(response => {
            this.applyResponse(response)
        }).catch(e => this.catchResponse(e));

        return this;
    }

    /** @inheritdoc */
    setAttributes(attributes) {
        super.setAttributes(attributes);

        if (this.comments) {
            let leadCommentService = new LeadCommentService();
            this.comments = leadCommentService.collection(this.comments);
        }

        if (this.pbxCall) {
            let pbxCallService = new PbxCallService();
            this.pbxCall = pbxCallService.collection(this.pbxCall);
        }

        if (this.tasksWait) {
            let taskService = new TaskService();
            this.tasksWait = taskService.collection(this.tasksWait);
        }

        if (this.tasks) {
            let taskService = new TaskService();
            this.tasks = taskService.collection(this.tasks);
        }
    }

    /**
     * Get duplicates items
     * @returns {array}
     */
    async dup() {
        return await this.http().get('/' + this.basePath + '/dup/' + this.id, { params: this.prepareGetRequest() }).then(response => {
            this.responseData = response.data;
            this.errors = {};

            if (response.data.meta) {
                this.metaTotal = response.data.meta.total;
                this.metaPerPage = response.data.meta.per_page;
            }

            let data = response.data.data;

            return this.collection(data);
        }).catch(e => this.catchResponse(e));
    }

    async new() {
        return await http.get('/' + this.basePath + '/new', { params: this.prepareRequest() }).then(response => {
            return response.data;
        }).catch(e => this.catchResponse(e));
    }
}

export default LeadService;