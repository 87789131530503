import Service from '@/services/Service';
import UserDeviceService from '@/modules/user/services/UserDeviceService';
import http from '@/utils/http-common'
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

class NotificationService extends Service {

    basePath = 'notification';

    firebaseConfig = {
        apiKey: 'AIzaSyDEHkyqFbhzCZ85Ocbn2fozN5_uYtXykCI',
        authDomain: 'vionvi-8b306.firebaseapp.com',
        projectId: 'vionvi-8b306',
        storageBucket: 'vionvi-8b306.appspot.com',
        messagingSenderId: '1077998215706',
        appId: '1:1077998215706:web:3fb89aa34c18f7ff65a7a3',
        measurementId: 'G-WM635Z9LJN',
    };

    firebaseToken = 'BM4sjHIO3x6ga3j4iWdd_Y20qaB6mRTfCdUNAEpDusTb49zT_HNVqitlt7YMpPI9-Ty1uSybSDi2xVhRC4ljyjU';

    newObject() {
        return new NotificationService();
    }
    
    async getUnreadNotification() {
        return await http.get('/' + this.basePath + '/get-unread', { params: this.prepareRequest() }).then(response => {
            return response.data.data;
        }).catch((e) => this.catchResponse(e));
    }

    async markAsRead() {
        return await http.get('/' + this.basePath + '/mark-as-read', { params: this.prepareRequest() }).then(response => {
            return response.data;
        }).catch((e) => this.catchResponse(e));
    }

    async firebase() {
        const app = initializeApp(this.firebaseConfig);

        // Получаем токен FCM. Первоначально выполняется сетевой запрос.
        // последующие вызовы getToken будут возвращаться из кэша.
        // const messaging = getMessaging(app);
        const messaging = getMessaging();
        // onMessage(messaging, (payload) => {
        //     console.log('Message received. ', payload);
        //     // ...
        // });
        return await getToken(messaging, { vapidKey: this.firebaseToken })
            .then(async (currentToken) => {
                if (currentToken) {
                    let userDeviceService = new UserDeviceService();
                    userDeviceService.name = window.navigator.userAgent;
                    userDeviceService.identity = window.navigator.userAgent;
                    userDeviceService.token = currentToken;
                    
                    // Токен FCM который нужно сохранять у нас на сервере
                    console.log('Это токен FCM:', currentToken);

                    if (!(await userDeviceService.save())) {
                        this.errors = userDeviceService.errors;

                        return false;
                    }

                    return true;
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
    }
}

export default NotificationService;