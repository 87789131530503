import Service from '@/services/Service';
import { t } from '@/lang';

class PayPositionService extends Service {

    // basePath = 'pay/position';

    newObject() {
        return new PayPositionService();
    }

    /**
     * Get VAT name
     * @returns {String}
     */
    getVatName() {
        return this.vat
            ? this.vat +
            ' %' +
            (this.isVatInCost ? ' (' + t('Included in price') + ')' : '')
            : t('Without VAT')
    }

    /**
     * Get sum without VAT
     * @returns {float}
     */
    getSum() {
        return this.qty * this.cost;
    }

    /**
     * Get VAT sum not in cost
     * @returns {float}
     */
    getSumVat() {
        if (this.vat > 0 && !this.isVatInCost) {
            return this.getSum() * this.vat / 100;
        } else {
            return 0;
        }
    }

    /**
     * Get VAT sum in cost
     * @returns {float}
     */
    getSumVatInc() {
        if (this.vat > 0 && this.isVatInCost) {
            return (this.getSum() * this.vat) / (this.vat + 100);
        } else {
            return 0;
        }
    }

    /**
     * Get sum with VAT
     * @returns {float}
     */
    getSumWithVat() {
        return this.getSum() + this.getSumVat();
    }
}

export default PayPositionService;