<template>
  <label v-if="label" :for="fieldStr">{{ label }}</label>
  <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="fa-solid fa-circle-info"></i></small>
  <div class="p-fileupload p-fileupload-advanced p-component">
    <div class="p-fileupload-buttonbar" style="padding-left: 0">
      <FileUpload
        ref="fileUpload"
        :id="fieldStr"
        mode="basic"
        v-model="serviceF"
        name="file"
        :url="url || apiUrl + '/' + service.basePath + '/upload'"
        @uploader="onUploader"
        :auto="true"
        :chooseLabel="chooseLabel || $t('Attach file')"
        :multiple="multiple"
        :class="
          'p-button p-button-text p-button-secondary p-button-sm w-100 ' +
          [service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : '']
        "
        :maxFileSize="210000000"
        :showUploadButton="false"
        :showCancelButton="false"
        :customUpload="true"
      ></FileUpload>
    </div>
    <template v-if="isGalleria">
      <FileGalleria :url="url" :service="service" :field="field" :edit="true" :adaptiveClass="adaptiveClass" />
    </template>
  </div>
  <div v-if="helpText">
    <p class="helpText">{{ helpText }}</p>
  </div>
  <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
    {{ service.errors ? service.errors[fieldStr] : null }}
  </div>
</template>

<script>
import FileUpload from 'primevue/fileupload';
import FileGalleria from '../FileGalleria.vue';
import http from '@/utils/http-common';
import Service from '@/services/Service';
import Env from '@/utils/Env';

export default {
  components: { FileUpload, FileGalleria },
  props: {
    service: Object,
    field: String,
    label: String,
    multiple: Boolean,
    url: String,
    helpText: String,
    tooltip: String,
    isGalleria: Boolean,
    chooseLabel: String,
    adaptiveClass: String,
  },
  data() {
    return {
      id: Math.random().toString(36).substr(2, 9) + new Date().getTime(),
      apiUrl: Env.getItem('VUE_APP_API_URL'),
    };
  },
  computed: {
    fieldStr() {
      return this.$arr.toStr(this.field);
    },
    serviceF: {
      get() {
        return this.$obj.getField(this.service, this.field);
      },
      set(v) {
        this.$obj.setField(this.service, this.field, v);
      },
    },
  },
  methods: {
    async onUploader(data) {
      let files = data.files;
      let formData = new FormData();

      this.service.tempId = this.service.tempId || this.id;
      let tempService = new Service();
      for (let file of files) {
        formData.set('file', file, file.name);
        formData.append('attribute', this.fieldStr);
        if (this.service.id) {
          formData.append('objectId', this.service.id);
        } else {
          formData.append('tempObjectId', this.service.tempId);
        }

        await http
          .post(this.url || '/' + this.service.basePath + '/upload', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((response) => {
            if (this.multiple) {
              this.serviceF = this.serviceF || [];
              this.serviceF.push(response.data.data);
            } else {
              this.serviceF = response.data.data;
            }
          })
          .catch((e) => {
            tempService.catchResponse(e);
            this.$toast.error({ detail: file.name + ': ' + tempService.getError() });
          });
      }

      if (!tempService.getError()) {
        this.$toast.success({ detail: this.$t('File downloaded') });
      }

      this.$refs.fileUpload.clear();
    },
  },
};
</script>
