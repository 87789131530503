import collections from './collections.json';
import fileFieldComponent from './fileFieldComponent.json';
import gridViewCellComponent from './gridViewCellComponent.json';
import gridViewComponent from './gridViewComponent.json';
import speedDialBtnComponent from './speedDialBtnComponent.json';
import toastPlugin from './toastPlugin.json';
import _other from './_other.json';

export const bg = {
  ...collections,
  ...fileFieldComponent,
  ...gridViewCellComponent,
  ...gridViewComponent,
  ...speedDialBtnComponent,
  ...toastPlugin,
  ..._other,
};
