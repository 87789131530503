<template>
  <div>
    <Galleria
      :value="serviceF"
      v-model:activeIndex="activeIndex"
      :fullScreen="true"
      :showItemNavigators="true"
      :showThumbnails="showThumbnails"
      v-model:visible="displayCustom"
      :numVisible="10"
    >
      <template #item="{ item }">
        <img v-if="item.isImage" :src="item.url" :alt="item.origname" />
        <span v-else class="text-center d-block">
          <i :class="'fa-3x ' + getFileIconClass(item.extension)"></i> <br />
          {{ item.origname }}
        </span>
      </template>
      <template #thumbnail="{ item }">
        <img :src="item.urlMin || item.url" :alt="item.alt" style="max-height: 50px" />
      </template>
    </Galleria>

    <!-- @todo: оптимизировать -->
    <div v-if="serviceF">
      <template v-if="mode === 'scroll'">
        <div class="d-flex flex-nowrap overflow-auto">
          <div
            v-for="(item, index) of Object.prototype.toString.call(serviceF) === '[object Array]'
              ? serviceF
              : [serviceF]"
            :key="index"
            class=""
          >
            <div class="position-relative img-thumbnail img-scroll-thumbnail">
              <a
                style="display: block; height: 100px; overflow-x: hidden; cursor: pointer"
                class="text-center"
                target="_blank"
                :href="item.url"
                @click="item.isImage ? onClickFile($event, index) : () => {}"
              >
                <img
                  v-if="item.isImage"
                  :src="item.urlMin || item.url"
                  :alt="item.alt"
                  style="max-height: 100%; position: relative; top: 50%; transform: translateY(-50%)"
                />
                <span
                  v-else
                  style="display: block; max-height: 100%; position: relative; top: 50%; transform: translateY(-50%)"
                >
                  <i :class="'fa-3x ' + getFileIconClass(item.extension)"></i> <br />
                  <small style="line-height: 1px">{{ item.origname }}</small>
                </span>
              </a>
              <Button
                v-if="edit"
                @click="onDeleteFile(index)"
                icon="fa-solid fa-trash-can"
                class="p-button-rounded p-button-danger position-absolute img-thumbnail-hover"
                style="width: 2rem; height: 2rem; top: -10px; right: -10px"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="mode === 'view'">
        <div class="d-flex flex-wrap" :class="{ 'justify-content-end': position == 'right' }">
          <div
            v-for="(item, index) of Object.prototype.toString.call(serviceF) === '[object Array]'
              ? serviceF
              : [serviceF]"
            :key="index"
          >
            <!-- class="col-12 md:col-2 sm:col-6 mb-2" -->
            <div class="position-relative">
              <Button
                v-if="edit"
                @click="onDeleteFile(index)"
                icon="fa-solid fa-trash-can"
                class="p-button-rounded p-button-danger position-absolute img-thumbnail-hover"
                style="width: 2rem; height: 2rem; top: -10px; right: -10px"
              />
              <a
                style="display: block; height: 100%; overflow-x: hidden; cursor: pointer"
                class="text-center"
                target="_blank"
                :href="item.url"
                @click="item.isImage ? onClickFile($event, index) : () => {}"
              >
                <img
                  v-if="item.isImage"
                  :src="item.urlMin || item.url"
                  :alt="item.alt"
                  style="max-height: 100%; position: relative; top: 50%"
                  class="img-fluid"
                />
                <span v-else style="display: block; max-height: 100%; position: relative; top: 50%">
                  <i :class="'fa-3x ' + getFileIconClass(item.extension)"></i> <br />
                  <small style="line-height: 1px">{{ item.origname }}</small>
                </span>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="mode === 'list'">
        <div class="grid">
          <!-- <Accordion>
          <AccordionTab header="Показать все файлы"> -->
          <div
            v-for="(item, index) of Object.prototype.toString.call(serviceF) === '[object Array]'
              ? serviceF
              : [serviceF]"
            :key="index"
            class="col-12 py-0"
          >
            <div class="d-flex align-items-center my-1 files-list">
              <div class="d-flex me-2">
                <i
                  :class="getFileIconClass(item.extension) + ' fs-2'"
                  :style="'color:' + getFileIconColor(item.extension)"
                ></i>
              </div>
              <div class="d-flex flex-column">
                <a target="_blank" :href="item.url" @click="item.isImage ? onClickFile($event, index) : () => {}">
                  <div>{{ item.origname + '.' + item.extension }}</div>
                </a>
                <div v-if="item.dtCreate" class="text-muted fs-7">
                  <span v-tooltip.bottom="$t('Upload date')">{{ $str.datetime(item.dtCreate) + ' ' }} </span>
                  <span v-tooltip.bottom="$t('Who uploaded it')">{{ item.userCreateName }}</span>
                </div>
              </div>

              <!-- <a target="_blank" :href="item.url" @click="item.isImage ? onClickFile($event, index) : () => {}">
                <span
                  ><i :class="item.isImage ? 'fa-regular fa-file-image' : getFileIconClass(item.extension)"></i
                ></span>
                {{ item.origname + '.' + item.extension }}
              </a> -->
              <Button
                v-if="edit"
                @click="onDeleteFile(index)"
                icon="fa-solid fa-trash-can"
                class="p-button-rounded p-button-text p-button-secondary button-hover"
                style="width: 2rem; height: 2rem"
              />
              <!-- @todo: Сделать кнопки действий с файлами через actionbtn -->
              <!-- <ActionBtn
                :actions="[
                  { action: 'delete', to: false, onClick: onDeleteFile, load: false },
                  { action: 'update', to: false, onClick: onEditFile, load: false },
                ]"
                :service="item"
              /> -->
            </div>
          </div>
          <!-- </AccordionTab>
        </Accordion> -->
        </div>
      </template>
      <template v-else>
        <div class="grid">
          <div
            v-for="(item, index) of Object.prototype.toString.call(serviceF) === '[object Array]'
              ? serviceF
              : [serviceF]"
            :class="adaptiveClass"
            :style="adaptiveStyle"
            :key="index"
          >
            <div class="position-relative img-thumbnail">
              <Button
                v-if="edit"
                @click="onDeleteFile(index)"
                icon="fa-solid fa-trash-can"
                class="p-button-rounded p-button-danger position-absolute img-thumbnail-hover"
                style="width: 2rem; height: 2rem; top: -10px; right: -10px"
              />
              <!-- Здесь специально не используются flex, т.к. задумано, что изображение ставится по высоте на 100%, центрируется. Но если по ширине не входит, то показывается от левого края -->
              <!-- Если нужно будет использовать полное центрирование, то использовать d-flex align-items-center justify-content-center -->
              <a
                style="display: block; height: 100px; overflow-x: hidden; cursor: pointer"
                class="text-center"
                target="_blank"
                :href="item.url"
                @click="item.isImage ? onClickFile($event, index) : () => {}"
              >
                <img
                  v-if="item.isImage"
                  :src="item.urlMin || item.url"
                  :alt="item.alt"
                  style="max-height: 100%; position: relative; top: 50%; transform: translateY(-50%)"
                />
                <span
                  v-else
                  style="display: block; max-height: 100%; position: relative; top: 50%; transform: translateY(-50%)"
                >
                  <i :class="'fa-3x ' + getFileIconClass(item.extension)"></i> <br />
                  <small style="line-height: 1px">{{ item.origname }}</small>
                </span>
              </a>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Galleria from 'primevue/galleria';
import ActionBtn from './ActionBtn.vue';
import http from '@/utils/http-common';

export default {
  components: { Accordion, AccordionTab, Button, Galleria, ActionBtn },
  props: {
    service: Object,
    field: String,
    edit: Boolean,
    showThumbnails: Boolean,
    mode: String,
    adaptiveClass: {
      type: String,
      default: 'col-6 sm:col-4 md:col-2',
    },
    adaptiveStyle: {
      type: String,
      default: 'margin-bottom: 20px',
    },
    url: String,
    position: String,
  },
  data() {
    return {
      activeIndex: 0,
      displayCustom: false,
    };
  },
  computed: {
    serviceF: {
      get() {
        return this.$obj.getField(this.service, this.field);
      },
      set(v) {
        this.$obj.setField(this.service, this.field, v);
      },
    },
  },
  methods: {
    async onDeleteFile(index) {
      await this.$confirm.require({
        message: this.$t('Are you sure you want to proceed?'),
        header: this.$t('Confirmation'),
        icon: 'fa-solid fa-triangle-exclamation',
        accept: async () => {
          let id;
          if (Object.prototype.toString.call(this.serviceF) === '[object Array]') {
            id = this.serviceF[index].id;

            this.serviceF.splice(index, 1);
          } else {
            id = this.serviceF.id;

            this.serviceF = null;
          }

          let url = this.url ? this.url : '/' + this.service.basePath + '/upload';

          await http.delete(url + '-delete/' + id);
        },
      });
    },
    onClickFile(e, index) {
      this.activeIndex = index;
      this.displayCustom = true;

      e.preventDefault();
    },
    getFileIconClass(extension) {
      if (extension === 'aac') {
        return 'bi bi-filetype-aac';
      } else if (extension === 'ai') {
        return 'bi bi-filetype-ai';
      } else if (extension === 'bmp') {
        return 'bi bi-filetype-bmp';
      } else if (extension === 'cs') {
        return 'bi bi-filetype-cs';
      } else if (extension === 'css') {
        return 'bi bi-filetype-css';
      } else if (extension === 'csv') {
        return 'bi bi-filetype-csv';
      } else if (extension === 'doc') {
        return 'bi bi-filetype-doc';
      } else if (extension === 'docx') {
        return 'bi bi-filetype-docx';
      } else if (extension === 'exe') {
        return 'bi bi-filetype-exe';
      } else if (extension === 'gif') {
        return 'bi bi-filetype-gif';
      } else if (extension === 'heic') {
        return 'bi bi-filetype-heic';
      } else if (extension === 'html') {
        return 'bi bi-filetype-html';
      } else if (extension === 'java') {
        return 'bi bi-filetype-java';
      } else if (['jpg', 'jpeg', 'jpe', 'jfif'].indexOf(extension) !== -1) {
        return 'bi bi-filetype-jpg';
      } else if (extension === 'json') {
        return 'bi bi-filetype-json';
      } else if (extension === 'key') {
        return 'bi bi-filetype-key';
      } else if (extension === 'm4p') {
        return 'bi bi-filetype-m4p';
      } else if (extension === 'md') {
        return 'bi bi-filetype-md';
      } else if (extension === 'mdx') {
        return 'bi bi-filetype-mdx';
      } else if (extension === 'mov') {
        return 'bi bi-filetype-mov';
      } else if (extension === 'mp3') {
        return 'bi bi-filetype-mp3';
      } else if (extension === 'otf') {
        return 'bi bi-filetype-otf';
      } else if (extension === 'pdf') {
        return 'bi bi-filetype-pdf';
      } else if (extension === 'php') {
        return 'bi bi-filetype-php';
      } else if (extension === 'png') {
        return 'bi bi-filetype-png';
      } else if (extension === 'ppt') {
        return 'bi bi-filetype-ppt';
      } else if (extension === 'pptx') {
        return 'bi bi-filetype-pptx';
      } else if (extension === 'psd') {
        return 'bi bi-filetype-psd';
      } else if (extension === 'py') {
        return 'bi bi-filetype-py';
      } else if (extension === 'raw') {
        return 'bi bi-filetype-raw';
      } else if (extension === 'rb') {
        return 'bi bi-filetype-rb';
      } else if (extension === 'sass') {
        return 'bi bi-filetype-sass';
      } else if (extension === 'scss') {
        return 'bi bi-filetype-scss';
      } else if (extension === 'sh') {
        return 'bi bi-filetype-sh';
      } else if (extension === 'sql') {
        return 'bi bi-filetype-sql';
      } else if (extension === 'svg') {
        return 'bi bi-filetype-svg';
      } else if (extension === 'tiff') {
        return 'bi bi-filetype-tiff';
      } else if (extension === 'tsx') {
        return 'bi bi-filetype-tsx';
      } else if (extension === 'ttf') {
        return 'bi bi-filetype-ttf';
      } else if (extension === 'txt') {
        return 'bi bi-filetype-txt';
      } else if (extension === 'wav') {
        return 'bi bi-filetype-wav';
      } else if (extension === 'woff') {
        return 'bi bi-filetype-woff';
      } else if (extension === 'xls') {
        return 'bi bi-filetype-xls';
      } else if (extension === 'xlsx') {
        return 'bi bi-filetype-xlsx';
      } else if (extension === 'xml') {
        return 'bi bi-filetype-xml';
      } else if (extension === 'yml') {
        return 'bi bi-filetype-yml';
      }

      return 'bi bi-file-earmark';
    },
    getFileIconColor(extension) {
      if (['xls', 'xlsx', 'numbers', 'ods', 'ots'].indexOf(extension) !== -1) {
        return '#107c41';
      } else if (['pdf', 'ppt', 'pptx', 'odp', 'otp', 'key'].indexOf(extension) !== -1) {
        return '#BB413E';
      } else if (['doc', 'docx', 'pages', 'odt', 'ott'].indexOf(extension) !== -1) {
        return '#1D70F5';
      }
    },
  },
};
</script>

<style scoped>
.img-thumbnail-hover {
  display: none;
}
.img-thumbnail.img-scroll-thumbnail {
  margin: 0 5px;
  padding: inherit;
  background-color: inherit;
  border: none;
}
.img-thumbnail.img-scroll-thumbnail img {
  border-radius: 10px;
}
.img-thumbnail:hover .img-thumbnail-hover {
  display: flex;
  z-index: 1;
}
.button-hover {
  display: none;
}
.files-list:hover .button-hover {
  display: flex;
}
</style>