import Env from '@/utils/Env';
import Storage from '@/utils/Storage';
import UserService from '@/modules/user/services/UserService';

export default {
  namespaced: true,
  state() {
    return {
      locale: Storage.getItem('locale') || Env.getItem('VUE_APP_DEFAULT_LOCAL') || 'en',
    };
  },
  actions: {
    setLocale({ state, commit, rootState }, locale) {
      state.locale = locale;
      rootState.app.$i18n.locale = locale;
      Storage.setItem('locale', locale);
      commit('reloadPage', null, { root: true });
    },
    async selLocaleWithUser({ dispatch, rootState, rootGetters }, locale) {
      rootState.loadingPage = true;

      dispatch('setLocale', locale);

      let user = rootGetters['auth/user'];
      if (user.id) {
        let userService = new UserService();
        userService.langLocale = locale;
        await userService.saveMe(['langLocale']);
        await dispatch('auth/info', null, { root: true });
      }

      rootState.loadingPage = false;
    },
  },
  getters: {
    locale(state) {
      return state.locale;
    },
  },
};
