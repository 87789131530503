import Storage from '../../utils/Storage';

export default {
    state() {
        return {
            task: [],
            chatMessage: [],
        }
    },
    mutations: {
        setTaskNotification(state, payload) {
            let result = [];

            for (let task of payload) {
                if (task.typeName == 'TaskNotification') {
                    result.push(task);
                }
            }

            state.task = result;
        },
        setChatMessageNotification(state, payload) {
            state.chatMessage = payload
        },
    },
    getters: {
        getTaskNotification(state) {
            return state.task
        },
        getChatMessageNotification(state) {
            return state.chatMessage
        },
    }
}