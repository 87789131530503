import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import store from '../store';

export function fastLoadFromParams(to, from) {
    // добавочные параметры для того чтобы работало стандартное взятие параметров из роута в компонентах
    // нужно, т.к. в определенных моментах created и mounted в компоненте срабатывает быстрее смены from на to
    from.params = to.params || {};
    from.query = to.query || {};
    from.hash = to.hash || {};
}

export function beforeEach(to, from, next) {
    // нужно, т.к. компонент по умолчанию перегружается только по name
    if (to.name === from.name && JSON.stringify(to.params) !== JSON.stringify(from.params)) {
        store.commit('reloadPage');

        fastLoadFromParams(to, from)
    }

    to.meta.auth = (to.meta.auth === undefined) ? true : to.meta.auth
    to.meta.layout = (to.meta.layout === undefined) ? 'main' : to.meta.layout

    const requireAuth = to.meta.auth;
    const isAuthenticated = store.getters['auth/isAuthenticated'];

    if (isAuthenticated) {
        if (to.path === '/auth') {
            next('/');
        } else {
            next();
        }
    } else if (requireAuth) {
        if (to.path === '/') {
            next('/auth');
        } else {
            next('/auth?message=auth');
        }
    } else {
        next();
    }
};

export function createRouter(options) {
    options.history = createWebHistory();
    let router = createVueRouter(options);

    router.beforeEach(beforeEach);

    return router;
}