import Service from '@/services/Service';
import http from '@/utils/http-common';

class SearchByService extends Service {

    basePath = 'search/by';

    newObject() {
        return new SearchByService();
    }

    /**
     * Get item by phone
     * 
     * @param {int} phone
     * @returns {Service}
     */
    async phone(phone) {
        return await http.get('/' + this.basePath + '/phone/' + phone).then(response => {
            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }
}

export default SearchByService;