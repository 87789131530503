<template>
  <div class="p-field">
    <!-- <label v-if="label" :for="fieldStr">{{ label }}</label>
    <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="pi pi-info-circle"></i></small> -->
    <ckeditor v-model="serviceF" :editor="editor" :config="editorConfig" :disabled="disabled" />
    <!-- <div v-if="helpText">
      <div class="helpText">{{ helpText }}</div>
    </div>
    <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
      {{ service.errors ? service.errors[fieldStr] : null }}
    </div> -->
  </div>
</template>

<script>
// Documentation: https://ckeditor.com/docs/ckeditor5/latest/getting-started/installation/vuejs-v3.html
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  Autosave,
  BalloonToolbar,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  HorizontalLine,
  HtmlComment,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  SelectAll,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  Undo,
} from 'ckeditor5';
// import { SlashCommand } from 'ckeditor5-premium-features';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import coreTranslations from 'ckeditor5/translations/ru.js';
import 'ckeditor5/ckeditor5.css';

export default {
  components: {
    Ckeditor,
  },
  props: {
    service: Object,
    field: String,
    label: String,
    helpText: String,
    tooltip: String,
    disabled: Boolean,
    url: String,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '<p>Текст</p>',
      editorConfig: {
        plugins: [
          AccessibilityHelp,
          Alignment,
          Autoformat,
          AutoImage,
          Autosave,
          BalloonToolbar,
          Base64UploadAdapter,
          BlockQuote,
          Bold,
          Code,
          CodeBlock,
          Essentials,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          GeneralHtmlSupport,
          Heading,
          HorizontalLine,
          HtmlComment,
          ImageBlock,
          ImageCaption,
          ImageInline,
          ImageInsert,
          ImageInsertViaUrl,
          ImageResize,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListProperties,
          MediaEmbed,
          Paragraph,
          SelectAll,
          SourceEditing,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextTransformation,
          TodoList,
          Underline,
          Undo,
        ],
        translations: [coreTranslations],
        toolbar: {
          items: [
            'heading',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'code',
            '|',
            'specialCharacters',
            'horizontalLine',
            'link',
            'insertImage',
            'mediaEmbed',
            'insertTable',
            'blockQuote',
            'codeBlock',
            '|',
            'alignment',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            'outdent',
            'indent',
            '|',
            'sourceEditing',
          ],
          shouldNotGroupWhenFull: false,
        },
        balloonToolbar: [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'code',
          'link',
          '|',
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
        ],
        fontFamily: {
          supportAllValues: true,
        },
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22],
          supportAllValues: true,
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph',
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading3',
            },
            {
              model: 'heading4',
              view: 'h4',
              title: 'Heading 4',
              class: 'ck-heading_heading4',
            },
            {
              model: 'heading5',
              view: 'h5',
              title: 'Heading 5',
              class: 'ck-heading_heading5',
            },
            {
              model: 'heading6',
              view: 'h6',
              title: 'Heading 6',
              class: 'ck-heading_heading6',
            },
          ],
        },
        htmlSupport: {
          allow: [
            {
              name: /^.*$/,
              styles: true,
              attributes: true,
              classes: true,
            },
          ],
        },
        image: {
          toolbar: [
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
            '|',
            'resizeImage',
            'linkImage',
          ],
        },
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: 'https://',
          decorators: {
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file',
              },
            },
          },
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        table: {
          contentToolbar: [
            'toggleTableCaption',
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties',
            'tableCellProperties',
          ],
        },
        // licenseKey: '<YOUR_LICENSE_KEY>',
        // Other configuration options...
      },
      // editor: ClassicEditor,
      // editorConfig: {
      //   plugins: [
      //     // EssentialsPlugin,
      //     // BoldPlugin,
      //     // ItalicPlugin,
      //     // LinkPlugin,
      //     // ParagraphPlugin,
      //     // Table,
      //     // TableToolbar,
      //     // Alignment,
      //     // BlockQuote,
      //     // Heading,
      //     // List,
      //     // ListProperties,
      //     // SimpleUploadAdapter,
      //     // Image,
      //     // ImageToolbar,
      //     // ImageCaption,
      //     // ImageStyle,
      //     // ImageResize,
      //     // LinkImage,
      //     // ImageUploadPlugin
      //   ],
      //   toolbar: {
      //     items: [
      //       'insertTable',
      //       'bold',
      //       'italic',
      //       'link',
      //       'undo',
      //       'redo',
      //       'alignment',
      //       'blockquote',
      //       'heading',
      //       'bulletedList',
      //       'numberedList',
      //       'image',
      //       'imageUpload'
      //     ]
      //   },
      //   simpleUpload: {
      //     // The URL that the images are uploaded to.
      //     uploadUrl: this.url ? process.env.VUE_APP_API_URL + this.url : process.env.VUE_APP_API_URL + '/wiki/article/upload-image',

      //     // Headers sent along with the XMLHttpRequest to the upload server.
      //     headers: {
      //       Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      //     },
      //   },
      //   language: 'ru',
      //   table: {
      //     contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
      //   },
      //   list: {
      //     properties: {
      //       styles: true,
      //       startIndex: true,
      //       reversed: true
      //     }
      //   },
      //   image: {
      //     toolbar: [
      //       'imageStyle:block',
      //       'imageStyle:side',
      //       '|',
      //       'toggleImageCaption',
      //       'imageTextAlternative',
      //       '|',
      //       'linkImage'
      //     ]
      //   }
      // },
    };
  },
  computed: {
    fieldStr() {
      return this.$arr.toStr(this.field);
    },
    serviceF: {
      get() {
        return this.$obj.getField(this.service, this.field);
      },
      set(v) {
        this.$obj.setField(this.service, this.field, v);
      },
    },
  },
};
</script>