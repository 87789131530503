import Service from '@/services/Service';
import TaskListItemService from './TaskListItemService';

class TaskListService extends Service {

    basePath = 'task/list';

    newObject() {
        return new TaskListService();
    }

    /** @inheritdoc */
    setAttributes(attributes) {
        super.setAttributes(attributes);

        if (this.items) {
            let taskListItemService = new TaskListItemService();
            this.items = taskListItemService.collection(this.items);
        }
    }
}

export default TaskListService;