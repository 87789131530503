<template>
  <div class="p-field">
    <label v-if="label" :for="fieldStr">{{ label }}</label>
    <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
    <Calendar
      :id="fieldStr"
      v-model="serviceFtemp"
      :showIcon="true"
      :monthNavigator="true"
      :yearNavigator="true"
      yearRange="1920:2030"
      @update:modelValue="
        onInput(fieldStr);
        $emit('change', $event);
      "
      :showButtonBar="true"
      :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
      :disabled="disabled"
      :selectionMode="selectionMode ?? 'single'"
      autocomplete="off"
      dateFormat="dd.mm.yy"
      :showTime="showTime"
      :showSeconds="showSeconds"
      :view="view"
      iconDisplay="input"
    />
    <div v-if="helpText">
      <div class="helpText">{{ helpText }}</div>
    </div>
    <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
      {{ service.errors ? service.errors[fieldStr] : null }}
    </div>
  </div>
</template>

<script>
import Calendar from 'primevue/calendar';

export default {
  components: {
    Calendar,
  },
  props: {
    service: Object,
    field: String | Array,
    label: String,
    disabled: Boolean,
    selectionMode: String,
    showTime: Boolean,
    showSeconds: Boolean,
    helpText: String,
    tooltip: String,
    view: String,
  },
  emits: ['change'],
  computed: {
    fieldStr() {
      return this.$arr.toStr(this.field);
    },
    serviceFtemp: {
      // Используем мнимую переменную, т.к. у Calendar присвоение идет всегда в Date и иначе выдает ошибку. А нам нужна строка
      get() {
        let sf = this.service['_' + this.fieldStr];
        if (sf === undefined) {
          if (this.serviceF) {
            if (Array.isArray(this.serviceF)) {
              sf = [];
              for (const i in this.serviceF) {
                sf.push(this.$str.newDate(this.serviceF[i]));
              }
            } else {
              sf = this.$str.newDate(this.serviceF);
            }
          } else {
            sf = this.serviceF;
          }
        }

        return sf;
      },
      set(v) {
        this.service['_' + this.fieldStr] = v;

        if (Array.isArray(v)) {
          this.serviceF = [];
          for (const i in v) {
            if (v[i]) {
              this.serviceF.push(this.format(v[i]));
            } else if (i) {
              // это нужно, чтобы при выборе одной даты в range мы только за эту дату и искали
              this.serviceF.push(this.format(v[0]));
            }
          }
        } else {
          this.serviceF = this.format(v);
        }
      },
    },
    serviceF: {
      get() {
        let serviceF = this.$obj.getField(this.service, this.field);

        // Стартово форматируем из unixtime
        if (typeof serviceF === 'number') {
          serviceF = this.format(serviceF);
        }

        return serviceF;
      },
      set(v) {
        this.$obj.setField(this.service, this.field, v);
      },
    },
  },
  methods: {
    format(value) {
      return this.$str.format(value, this.showTime ? 'datetime' : 'date');
    },
    onInput(fieldStr) {
      if (this.service.errors && this.service.errors[fieldStr]) {
        delete this.service.errors[fieldStr];
      }
    },
  },
};
</script>