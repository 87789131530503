import axios from 'axios';
import Env from './Env';
import Storage from './Storage';
import store from '../store';
import { t } from '../lang';
import Arr from '../utils/Arr';
// import router from '../router';

export function getHttp() {
  let instance = axios.create({
    baseURL: Env.getItem('VUE_APP_API_URL'),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Storage.getItem('jwt-token'),
    },
    validateStatus: function (status) {
      return status == 200 || status == 201;
    },
  });

  instance.interceptors.response.use(null, function (error) {
    if (error.response && error.response.status === 401) {
      Storage.setItem('jwt-token', null);
      window.location.href = '/'; // Пока так, а то через router не работает редирект на авторизацию
      // router.push('/auth');
    }

    return Promise.reject(error);
  });

  instance.catchResponse = function (error, name) {
    let errors = {};

    let response = error.response;
    if (response) {
      if (response.status === 422) {
        if (response.data.errors) {
          errors = Arr.arrOneLevel(response.data.errors);
        } else {
          errors = { [response.status]: response.status + ': ' + t('Validate error') };
        }

        return errors; // Возвращаем, т.к. 422 ошибки валидации не пишем в общий стек ошибок
      } else if (response.status === 403) {
        errors = { [response.status]: response.status + ': ' + t('Access denied') };
      } else if (response.status === 404) {
        errors = { [response.status]: response.status + ': ' + t('Not found') };
      } else {
        errors = {
          [response.status]: response.status + (response.data.code ? '-' + response.data.code : '') + ': ' + t('Error'),
        };
      }
    } else if (error.message) {
      errors = { 0: error.message };
    } else {
      throw error;
    }

    store.commit('addErrors', { [name || 'Http']: errors });

    return errors;
  };

  return instance;
}

export default getHttp();
