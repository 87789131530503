import { bg } from './bg/__bg';
import { ru } from './ru/__ru';

export const messages = {
  bg,
  ru,
};

export const dateTimeFormats = {
  us: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      // hour12: true,
    },
  },
};
