import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './lang';

import LoadScript from 'vue-plugin-load-script';
import { dragscrollNext } from 'vue-dragscroll';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import StyleClass from 'primevue/styleclass';
import BadgeDirective from 'primevue/badgedirective';
import jsPDF from 'jspdf';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

import '@fortawesome/fontawesome-free/css/all.min.css';

import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import arr from './plugins/arr';
import obj from './plugins/obj';
import str from './plugins/str';
import toast from './plugins/toast';

// лог пушера
// Pusher.log = function (message) { window.console.log(message); }

window.VUE_APP_MODE = process.env.VUE_APP_MODE;

let app = createApp(App)
  .use(store)
  .use(PrimeVue)
  .directive('styleclass', StyleClass)
  .directive('badge', BadgeDirective)
  .use(ConfirmationService)
  .use(jsPDF)
  .use(ToastService)
  .use(router)
  .use(i18n)
  .use(LoadScript)
  .use(arr)
  .use(obj)
  .use(str)
  .use(toast)
  .directive('tooltip', Tooltip)
  .directive('dragscroll', dragscrollNext)
  .mount('#app');

store.commit('setApp', app);
