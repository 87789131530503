<template>
  <component :is="layout + '-layout'" />
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import MainLayout from './layout/MainLayout.vue';
import StartLayout from './layout/StartLayout.vue';

export default {
  setup() {
    const route = useRoute();
    return {
      layout: computed(() => route.meta.layout),
    };
  },
  metaInfo: {
    meta: [{ name: 'apple-mobile-web-app-capable', content: 'yes' }],
  },
  components: {
    MainLayout,
    StartLayout,
  },
  mounted() {
    // Это нужно для того чтобы использовать далее router и toast в вызовах store
    this.$store.commit('setRouter', this.$router);
    this.$store.commit('setToast', this.$toast);

    this.$primevue.config.locale.accept = this.$t('Yes');
    this.$primevue.config.locale.reject = this.$t('No');
    this.$primevue.config.locale.today = this.$t('Today');
    this.$primevue.config.locale.clear = this.$t('Clear');
    this.$primevue.config.locale.monthNames = [
      this.$t('January'),
      this.$t('February'),
      this.$t('March'),
      this.$t('April'),
      this.$t('May'),
      this.$t('June'),
      this.$t('July'),
      this.$t('August'),
      this.$t('September'),
      this.$t('October'),
      this.$t('November'),
      this.$t('December'),
    ];
    this.$primevue.config.locale.monthNamesShort = [
      this.$t('Jan'),
      this.$t('Feb'),
      this.$t('Mar'),
      this.$t('Apr'),
      this.$t('May'),
      this.$t('Jun'),
      this.$t('Jul'),
      this.$t('Aug'),
      this.$t('Sep'),
      this.$t('Oct'),
      this.$t('Nov'),
      this.$t('Dec'),
    ];
    this.$primevue.config.locale.dayNamesMin = [
      this.$t('Su'),
      this.$t('Mo'),
      this.$t('Tu'),
      this.$t('We'),
      this.$t('Th'),
      this.$t('Fr'),
      this.$t('Sa'),
    ];
    this.$primevue.config.locale.firstDayOfWeek = 1;
    this.$primevue.config.locale.dateFormat = 'dd.mm.yy';
  },
};
</script>
