import Service from '@/services/Service';

class UserDeviceService extends Service {

    basePath = 'user/device';

    newObject() {
        return new UserDeviceService();
    }

}

export default UserDeviceService;