<template>
  <li>
    <i
      class="bi bi-question-circle cursor-pointer"
      style="font-size: 1.2rem; color: #6f7985"
      v-tooltip.bottom="$t('Help')"
      @click="$store.commit('openSupportBar')"
    ></i>
    <Sidebar v-model:visible="$store.getters.supportBar.visible" position="right">
      <div class="pl-3 pr-4 pt-1">
        <h5>{{ $t('Contact support') }}</h5>
        <div class="d-grid gap-2">
          <Button
            label="Написать в Телеграм"
            icon="bi bi-telegram"
            size="small"
            class="p-button-info p-button-outlined"
            onclick="window.open('https://t.me/vionvi_bot','_blank');"
          />
          <Button
            label="Написать в WhatsApp"
            icon="bi bi-whatsapp"
            size="small"
            class="p-button-success p-button-outlined"
            onclick="window.open('https://wa.me/78005516632','_blank');"
          />
          <Button
            label="База знаний"
            icon="bi bi-book"
            size="small"
            class="p-button-secondary p-button-outlined"
            onclick="window.open('https://vionvi.ru/support/?utm_source=crm-clients','_blank');"
          />
          <Button
            label="Обновления"
            icon="bi bi-megaphone"
            size="small"
            class="p-button-secondary p-button-outlined"
            onclick="window.open('https://vionvi.ru/updates/?utm_source=crm-clients','_blank');"
          />
        </div>
        <div class="d-flex align-items-center pt-2">
          <div>
            {{ $t('Account ID') }}: <strong> {{ systemId }} </strong>
          </div>
        </div>
        <div>
          <div class="pt-2">
            <div class="text-muted fs-7">Позвоните нам (с 10 до 21 МСК)</div>
            <div class="fs-7 fw-bold my-2">8 (800) 551-66-32</div>
            <div class="text-muted fs-7">Подписывайтесь на соц.сети</div>
            <div class="d-flex align-items-center pt-2">
              <a href="https://vk.com/vicrm" target="_blank" rel="noopener noreferrer">
                <i class="fa-brands fa-vk" style="font-size: 1.4rem; width: 35px; color: #0077ff"></i>
              </a>
              <a href="https://t.me/vionvi" target="_blank" rel="noopener noreferrer">
                <i class="fa-brands fa-telegram" style="font-size: 1.4rem; width: 35px; color: #54a9eb"></i>
              </a>
              <a href="https://www.youtube.com/@vionvi" target="_blank" rel="noopener noreferrer">
                <i class="fa-brands fa-youtube" style="font-size: 1.4rem; width: 35px; color: #c4302b"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
    <span class="p-ink"></span>
  </li>
</template>

<script>
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import Env from '@/utils/Env';

export default {
  components: {
    Sidebar,
    Button,
  },
  data() {
    return {
      systemId: Env.getItem('VUE_APP_ID'),
    };
  },
};
</script>