import Storage from '../../utils/Storage';

export default {
    state() {
        return {
            filterCache: Storage.getItem('filterCache') || {},
        }
    },
    mutations: {
        setFilterCache(state, payload) {
            state.filterCache = payload
            // Пока ставим на 10 часов, когда все идеально будет работать уберем ограничение
            Storage.setItem('filterCache', payload, 1000 * 60 * 60 * 10);
        },
    },
    actions: {
        setFilterCache(context) {
            context.commit('setFilterCache')
        },
    },
    getters: {
        getFilterCache(state) {
            return state.filterCache
        }
    }
}