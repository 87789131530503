import Service from '@/services/Service';
import http from '@/utils/http-common';
import store from '@/store';

class AppWazzupService extends Service {
  basePath = 'app/wazzup';

  newObject() {
    return new AppWazzupService();
  }

  async global() {
    return await http
      .post('/' + this.basePath + '/global', this.prepareRequest())
      .then((response) => {
        return response.data.data.url;
      })
      .catch((e) => this.catchResponse(e));
  }

  async integration() {
    return await http
      .post('/' + this.basePath + '/integration', this.prepareRequest())
      .then((response) => {
        return response.data.data.value;
      })
      .catch((e) => this.catchResponse(e));
  }

  async socket(userId) {
    let apps = store.getters['auth/app'];
    
    if (apps.length > 0) {
      for (let app of apps) {
        if (app.identifier == 'wazzup') {
          for (let set of app.sets) {
            if (set.name == 'token') {
              var apiKey = set.value
            }
          }
        }
      }
    }

    // Опции для подключения нотификации
    const connectOptions = {
      path: '/ws-counters/',
      transports: ['websocket', 'polling'],
    };

    fetch(`https://integrations.wazzup24.com/counters/ws_host/api_v3/${apiKey}`)
      .then((response) => response.json())
      .then((data) => {
        const { host } = data;
        const client = io(`https://${host}`, connectOptions);
        client.on('connect', () => {
          client.emit('counterConnecting', {
            type: 'api_v3',
            apiKey,
            userId,
          });
        });

        // Подтверждение подключения
        client.on('counterConnected', () => console.log('Connected to Wazzup notifications'));

        // Обновление счетчика неотвеченных
        client.on('counterUpdate', (data) => {
          const { counterV2 } = data;
          store.commit('setCountWazzup', counterV2);
        });
      })
      .catch((error) => {
        console.log('Connection error Wazzup', error);
      });
  }
}

export default AppWazzupService;
