import Service from '@/services/Service';

class PbxCallService extends Service {

    basePath = 'pbx/call';

    newObject() {
        return new PbxCallService();
    }
}

export default PbxCallService;