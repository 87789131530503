import { createStore } from 'vuex';
import appWazzup from './modules/appWazzup.module';
import auth from './modules/auth.module';
import chatMessage from './modules/chatMessage.module';
import contactData from './modules/contactData.module';
import contract from './modules/contract.module';
import filterCache from './modules/filterCache.module';
import lang from './modules/lang.module';
import notification from './modules/notification.module';
import pbxZadarma from './modules/pbxZadarma.module';
import realty from './modules/realty.module';
import userSidebar from './modules/userSidebar.module';

export default createStore({
  state() {
    return {
      app: null,
      menuItems: [],
      loadingPage: false,
      modals: [],
      modalActiveIndex: null,
      routerViewKey: 0, // page refresh key
      supportBar: { visible: false },
      errors: {},
      router: null,
      toast: null,
    };
  },
  mutations: {
    setApp(state, app) {
      state.app = app;
    },
    setLoadingPage(state, loadingPage) {
      state.loadingPage = loadingPage;
    },
    pushModal(state, modal) {
      // удаляем все закрытые модальные окна
      for (let i = state.modals.length; i--; ) {
        if (!state.modals[i].isOpen) {
          state.modals.splice(i, 1);
        }
      }

      // и добавляем новое модальное окно
      state.modals.push(modal);
      state.modalActiveIndex = state.modals.length - 1;
    },
    replaceModal(state, modal) {
      state.modals[state.modals.length - 1] = modal;
    },
    setModal(state, index) {
      index = index || -1; // при -1 закрываются все окна
      state.modalActiveIndex = null;

      // открываем окна по указанный index
      for (let i in state.modals) {
        if (i > index) {
          state.modals[i].isOpen = false;
          state.modals[i].isAutoClose = true;
        } else {
          state.modals[i].isOpen = true;
          state.modals[i].isAutoClose = false;
          state.modalActiveIndex = i;
        }
      }
    },
    delModals(state) {
      state.modals = [];
    },
    reloadPage(state) {
      state.routerViewKey++;
    },
    openSupportBar(state) {
      state.supportBar.visible = true;
    },
    addErrors(state, errors) {
      state.errors = { ...state.errors, ...errors };
    },
    clearErrors(state) {
      state.errors = {};
      if (state.toast) {
        state.toast.removeAllGroups();
      }
    },
    setRouter(state, router) {
      state.router = router;
    },
    setToast(state, toast) {
      state.toast = toast;
    },
  },
  getters: {
    loadingPage(state) {
      return state.loadingPage;
    },
    modals(state) {
      return state.modals;
    },
    modalActiveIndex(state) {
      return state.modalActiveIndex;
    },
    routerViewKey(state) {
      return state.routerViewKey;
    },
    can: (state, getters) => (permissionName) => {
      return getters['auth/isAdmin'] || getters['auth/permissionNames'].includes(permissionName);
    },
    canTo: (state, getters) => (to) => {
      // check permissions from meta.can routes
      if (to && to.name) {
        let routerResolve = state.router.resolve(to);
        if (routerResolve && routerResolve.meta && routerResolve.meta.can && !getters.can(routerResolve.meta.can)) {
          return false;
        }
      }

      return true;
    },
    supportBar(state) {
      return state.supportBar;
    },
  },
  modules: {
    appWazzup,
    auth,
    chatMessage,
    contactData,
    contract,
    filterCache,
    lang,
    notification,
    pbxZadarma,
    realty,
    userSidebar,
  },
});
