<template>
  <Toast position="top-right" group="main">
    <template #message="{ message }">
      <InfoCircleIcon v-if="message.severity === 'info'" class="p-toast-message-icon" />
      <ExclamationTriangleIcon v-else-if="message.severity === 'warn'" class="p-toast-message-icon" />
      <TimesCircleIcon v-else-if="message.severity === 'error'" class="p-toast-message-icon" />
      <CheckIcon v-else-if="message.severity === 'success'" class="p-toast-message-icon" />
      <div class="p-toast-message-text">
        <span class="p-toast-summary">{{ message.summary }}</span>
        <div class="p-toast-detail">
          <ul v-if="typeof message.detail === 'object'" class="mb-0 ps-1">
            <li v-for="(detail, index) of message.detail" :key="index">
              <div v-if="typeof detail === 'object'">
                <div v-for="d of detail" :key="d">
                  {{ index }}:
                  <span v-html="d"></span>
                </div>
              </div>
              <div v-else>
                {{ detail }}
              </div>
            </li>
          </ul>
          <div v-else>
            {{ message.detail }}
          </div>
        </div>
      </div>
    </template>
  </Toast>
</template>

<script>
import Toast from 'primevue/toast';
import InfoCircleIcon from 'primevue/icons/infocircle';
import ExclamationTriangleIcon from 'primevue/icons/exclamationtriangle';
import TimesCircleIcon from 'primevue/icons/timescircle';
import CheckIcon from 'primevue/icons/check';

export default {
  components: { Toast, InfoCircleIcon, ExclamationTriangleIcon, TimesCircleIcon, CheckIcon },
};
</script>