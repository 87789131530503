<template>
  <div class="p-field m-0">
    <div class="d-flex align-items-stretch global-search global-search-panel">
      <InputGroup @keydown.enter="searchMeilisearch">
        <InputText v-model="service['search']" :placeholder="$t('Search by Lead, Contract, Client')" />
        <Button @click="searchMeilisearch" icon="pi pi-search" class="p-button-secondary p-button-outlined" />
      </InputGroup>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';

export default {
  components: {Button, InputGroup, InputText},
  props: {
    service: Object,
  },
  data() {
    return {
      resultsMeilisearch: null,
    };
  },
  created() {
    this.service.search = this.$route.query.search
  },
  methods: {
    async searchMeilisearch(e) {
      this.$router.push({name: 'Search', query: {search: this.service.search}})
    },
  },
};
</script>