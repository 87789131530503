<template>
  <SpeedDial
    v-if="modelLength > 0"
    :ref="(el) => (container = el)"
    :model="visibleModel"
    :direction="type === 'left' ? 'right' : 'left'"
    :showIcon="showIcon"
    @click="modelLength > 1 ? () => {} : onClick(visibleModel[0])"
    :class="'custom-speeddial-' + (type || 'fixed')"
    :buttonClass="buttonClass"
    :rotateAnimation="false"
    :disabled="visibleModel[0].disabled"
  >
    <template #button>
      <Link
        :to="container.disabled || modelLength > 1 || visibleModel[0].onClick ? false : visibleModel[0].to"
        :data="service"
        v-tooltip.left="tooltip"
        role="menuitem"
        @click="container.disabled ? () => {} : container.onClick($event)"
      >
        <Button
          type="button"
          :class="[container.buttonClass, 'p-speeddial-button p-button-rounded']"
          :icon="container.showIcon"
          :disabled="container.disabled"
          :label="label"
        />
      </Link>
    </template>
    <template #item="{ item }">
      <Link
        v-if="modelLength > 1"
        :to="item.onClick ? false : item.to"
        :data="service"
        v-tooltip.top="item.label"
        role="menuitem"
        class="p-speeddial-action"
        @click="onClick(item)"
      >
        <span :class="'p-speeddial-action-icon ' + item.icon"></span>
      </Link>
    </template>
  </SpeedDial>
</template>

<script>
import SpeedDial from 'primevue/speeddial';
import Button from 'primevue/button';
import Link from './Link.vue';

export default {
  components: { SpeedDial, Button, Link },
  props: {
    actions: Array | Function,
    service: Object,
    type: String,
    isSuccessToast: Boolean,
    length: Number,
  },
  emits: ['load', 'afterClick', 'update:length'],
  data() {
    return {
      container: {},
      confirmMessage: this.$t('Are you sure you want to proceed?'), // выносим в переменные, т.к. почему-то при втором обращении к компоненту $t не срабатывает если его использовать напрямую
      confirmHeader: this.$t('Confirmation'),
      visibleModel: [],
    };
  },
  computed: {
    model() {
      let model = typeof this.actions === 'function' ? this.actions(this.service) : this.actions;

      if (!model) {
        return model;
      }

      for (let i = model.length; i--; ) {
        let item = model[i];

        if (
          item === 'show' ||
          item === 'create' ||
          item === 'update' ||
          item === 'save' ||
          item === 'delete' ||
          item === 'export' ||
          item === 'importCreate' ||
          item === 'importSave' ||
          item === 'wazzup'
        ) {
          item = model[i] = { action: item };
        }

        if (item.action === 'show') {
          model[i].label = item.label || this.$t('Show');
          model[i].icon = item.icon || 'fa-regular fa-eye';
          model[i].to = item.to === undefined ? (service) => this.$str.topPath() + '/' + service.id : item.to;
        } else if (item.action === 'create') {
          if (!this.$store.getters.can(this.$str.topPath().slice(1) + '.store')) {
            model.splice(i, 1);
            continue;
          }

          model[i].label = item.label || this.$t('Create');
          model[i].icon = item.icon || 'fa-solid fa-plus';
          model[i].to = item.to === undefined ? () => this.$str.topPath() + '/create' : item.to;
        } else if (item.action === 'update') {
          if (!this.$store.getters.can(this.$str.topPath().slice(1) + '.update')) {
            model.splice(i, 1);
            continue;
          }

          model[i].label = item.label || this.$t('Edit');
          model[i].icon = item.icon || 'fa-solid fa-pencil';
          model[i].to = item.to === undefined ? (service) => this.$str.topPath() + '/update/' + service.id : item.to;
        } else if (item.action === 'save') {
          model[i].label = item.label || this.$t('Save');
          model[i].icon = item.icon || 'fa-solid fa-check';
          model[i].onClick = item.onClick === undefined ? this.onSave : item.onClick;
          model[i].to = item.to === undefined ? this.$str.topPath() : item.to;
        } else if (item.action === 'delete') {
          if (!this.$store.getters.can(this.$str.topPath().slice(1) + '.destroy')) {
            model.splice(i, 1);
            continue;
          }

          model[i].label = item.label || this.$t('Delete');
          model[i].icon = item.icon || 'fa-solid fa-trash-can';
          model[i].onClick = item.onClick === undefined ? this.onDelete : item.onClick;
        } else if (item.action === 'export') {
          if (!this.$store.getters.can(this.$str.topPath().slice(1) + '.export')) {
            model.splice(i, 1);
            continue;
          }

          model[i].label = item.label || this.$t('Export');
          model[i].icon = item.icon || 'fa-solid fa-file-export';
          model[i].onClick = item.onClick === undefined ? this.onExport : item.onClick;
        } else if (item.action === 'importCreate') {
          if (!this.$store.getters.can(this.$str.topPath().slice(1) + '.import')) {
            model.splice(i, 1);
            continue;
          }

          model[i].label = item.label || this.$t('Import');
          model[i].icon = item.icon || 'fa-solid fa-file-import';
          model[i].to = item.to === undefined ? () => this.$str.topPath() + '/import' : item.to;
        } else if (item.action === 'importSave') {
          model[i].label = item.label || this.$t('Import');
          model[i].icon = item.icon || 'fa-solid fa-file-import';
          model[i].onClick = item.onClick === undefined ? this.onImport : item.onClick;
        } else if (item.action === 'wazzup') {
          model[i].label = item.label || this.$t('Wazzup');
          model[i].icon = item.icon || 'fa-regular fa-comment';
          model[i].to = item.to === undefined ? this.$str.topPath() : item.to;
        }
      }

      return model;
    },
    modelLength() {
      this.visibleModel = [];
      let length = 0;

      if (this.model) {
        for (let i in this.model) {
          // такое присвоение нужно, т.к. дефолтный visible не понимает функцию и перезаписывать его нельзя, т.к. это один actions для всех строк
          this.model[i]._visible =
            this.model[i]._visible === undefined ? this.model[i].visible : this.model[i]._visible;
          delete this.model[i].visible;
          if (this.isVisible(this.model[i]._visible)) {
            this.visibleModel.push(this.model[i]);
            length++;
          }
        }
      }

      this.$emit('update:length', length);

      return length;
    },
    showIcon() {
      if (this.modelLength === 1) {
        return this.visibleModel[0].icon;
      }

      return 'fa-solid fa-ellipsis-h';
    },
    tooltip() {
      if (this.modelLength === 1 && this.visibleModel[0].isTooltip) {
        return this.visibleModel[0].label;
      }
    },
    label() {
      if (this.modelLength === 1 && this.visibleModel[0].isLabel) {
        return this.visibleModel[0].label;
      }
    },
    buttonClass() {
      if (this.modelLength === 1) {
        let item = this.visibleModel[0];

        if (item.action === 'create' || item.action === 'save') {
          return 'p-button-primary';
        } else if (item.action === 'delete') {
          return 'p-button-danger';
        }
      }

      return 'p-button-secondary';
    },
  },
  methods: {
    async onSave(service) {
      if (await service.save()) {
        if (this.isSuccessToast) {
          this.$toast.success({ detail: 'Сохранено' });
        }
      }
    },
    async onImport(service) {
      if (await service.import()) {
        if (this.isSuccessToast) {
          this.$toast.success({ detail: 'Импортировано' });
        }
      }
    },
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async onDelete(service) {
      let skip = false;

      await this.$confirm.require({
        message: this.confirmMessage,
        header: this.confirmHeader,
        icon: 'fa-solid fa-triangle-exclamation',
        accept: async () => {
          if (service._selectedItems) {
            let ids = [];
            for (let item of service._selectedItems) {
              ids.push(item.id);
            }
            await service.deleteMass(ids);
          } else {
            await service.delete();
          }

          skip = true;
        },
        reject: () => {
          service._isReject = true;

          skip = true;
        },
      });

      while (!skip) {
        // это нужно, т.к. confirm от primeView не работает с асинхронностью
        await this.sleep(500);
      }
    },
    async onExport(service) {
      let data = await service.export();

      const link = document.createElement('a');
      link.href = data.url;
      link.click();
    },
    async onClick(item) {
      if (item.onClick) {
        // При загрузке дизейблим кнопку и меняем иконку до конца выполнения функции
        let icon = item.icon;
        item.icon = 'pi pi-spin pi-spinner';
        item.disabled = true;
        await item.onClick(this.service, item);
        item.icon = icon;
        item.disabled = false;

        if (item.onClickAfter) {
          await item.onClickAfter(this.service, item);
        }

        this.$emit('afterClick');

        if (this.service.getError()) {
          this.$toast.error({ detail: this.service.getError() });
        } else if (this.service._isReject) {
          delete this.service._isReject;
        } else {
          if (item.to) {
            if (item.to.href) {
              location.href = typeof item.to.href === 'function' ? item.to.href(this.service) : item.to.href;
            } else {
              this.$router.push(typeof item.to === 'function' ? item.to(this.service) : item.to);
            }
          } else if (item.load !== false) {
            this.$emit('load');
          }
        }
      }
    },
    isVisible(modelVisible) {
      let visible = typeof modelVisible === 'function' ? modelVisible(this.service) : modelVisible;

      if (visible || visible === undefined) {
        return true;
      }

      return false;
    },
  },
};
</script>