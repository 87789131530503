import router from '../../router'
import AuthService from '../../services/AuthService';
import Storage from '../../utils/Storage';

export default {
    namespaced: true,
    state() {
        return {
            token: Storage.getItem('jwt-token'), // Получение токена из Storage при его наличии. Если его нет, то авторизация.
            info: Storage.getItem('info') || {},
        };
    },
    mutations: {
        setToken(state, token) {
            state.token = token;

            Storage.setItem('jwt-token', token);
        },
        setInfo(state, info) {
            state.info = info;

            Storage.setItem('info', info);
        },
    },
    actions: {
        async login({ commit, dispatch, rootState }, service) {
            rootState.loadingPage = true;

            let data = await service.token();

            if (data) {
                commit('setToken', data.access_token);
                commit('setInfo', null);

                // not work - not token in http
                // await dispatch('info');

                router.go();
            }

            rootState.loadingPage = false;
        },
        async logout({ commit, rootState }) {
            rootState.loadingPage = true;

            let authService = new AuthService();
            await authService.tokenDelete();

            commit('setToken', null);
            commit('setInfo', null);
            // todo: переделать - при выходе очищать все
            Storage.setItem('pbx-zadarma-token', null);

            router.go();

            rootState.loadingPage = false;
        },
        async info({ commit, rootState }) {
            rootState.loadingPage = true;

            let authService = new AuthService();
            let info = await authService.info();

            commit('setInfo', info);

            rootState.loadingPage = false;
        },
        async init({ state, dispatch }) {
            if (!Object.keys(state.info).length) {
                await dispatch('info');
            }
        }
    },
    getters: {
        isAuthenticated(state) {
            return !!state.token;
        },
        struc(state) {
            return state.info.struc || [];
        },
        user(state) {
            return state.info.user || {};
        },
        isAdmin(state) {
            return state.info.isAdmin || false;
        },
        permissionNames(state) {
            return state.info.permissionNames || [];
        },
        app(state) {
            return state.info.app || [];
        },
        isAppIdentifier: (state, getters) => (identifier) => {
            let identifiers = state.info.appIdentifiers || [];

            for (let ident of identifiers) {
                if (ident == identifier) {
                    return true;
                }
            }

            return false;
        },
    }
}
