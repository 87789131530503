import Storage from '../../utils/Storage';

export default {
  state() {
    return {
      viewRealtyRentFlatSelection: Storage.getItem('realtyRentFlatViewSelection') || { name: 'RealtyRentFlatList' },
    };
  },
  mutations: {
    setRealtyRentFlatViewSelection(state, payload) {
      state.viewRealtyRentFlatSelection = payload;
      Storage.setItem('realtyRentFlatViewSelection', payload);
    },
  },
  getters: {
    getRealtyRentFlatViewSelection(state) {
      return state.viewRealtyRentFlatSelection
    },
  }
};
